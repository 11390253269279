import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./CTA.scss";

/** Generic call to action */
export const CTA = (
  /** @type {PropTypes.InferPropsInner<CTA.propTypes>} */
  { text, type, link, className }
) => {
  switch (type) {
    case "text-with-arrow":
    case "text":
      if (link) {
        return (
          <GatsbyLink
            className={`cta cta-${type}${className ? ` ${className}` : ``}`}
            to={link}
          >
            <span>{text}</span>
            {type === "text-with-arrow" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 39 32"
                className="cta-arrow"
              >
                <path
                  fill="currentColor"
                  d="M23.85.44a1.65,1.65,0,0,0-2.3.06,1.58,1.58,0,0,0,0,2.23l0,0L34.07,14.4H1.6a1.6,1.6,0,0,0,0,3.2H34L21.61,29.24a1.58,1.58,0,0,0-.09,2.23l0,0a1.65,1.65,0,0,0,2.3.06L38,18.26a3.16,3.16,0,0,0,.08-4.47.3.3,0,0,0-.08-.08Z"
                  fillRule="evenodd"
                />
              </svg>
            ) : null}
          </GatsbyLink>
        );
      }
      return (
        <div className={`cta cta-${type}${className ? ` ${className}` : ``}`}>
          <span>{text}</span>
          {type === "text-with-arrow" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 39 32"
              className="cta-arrow"
            >
              <path
                fill="currentColor"
                d="M23.85.44a1.65,1.65,0,0,0-2.3.06,1.58,1.58,0,0,0,0,2.23l0,0L34.07,14.4H1.6a1.6,1.6,0,0,0,0,3.2H34L21.61,29.24a1.58,1.58,0,0,0-.09,2.23l0,0a1.65,1.65,0,0,0,2.3.06L38,18.26a3.16,3.16,0,0,0,.08-4.47.3.3,0,0,0-.08-.08Z"
                fillRule="evenodd"
              />
            </svg>
          ) : null}
        </div>
      );
    default:
      throw new Error("Missing type for CTA");
  }
};

CTA.defaultProps = {
  text: "Read More",
  type: "text-with-arrow",
  link: undefined,
  action: undefined,
  buttonVariant: "outlined",
};
