import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../GatsbyLink";
import RenderContent from "../RenderContent";
import { SocialLinks } from "../SocialLinks";
import { decodeEntities, scrollTo } from "../../utils/helpers";
import loadable from "@loadable/component";
import * as FooterLogoReverse from "../../../theme/logos/pilbara-solar-reversed.png";
import "../../../theme/LogoFooter.scss";
import "./Footer.scss";

const CookieNotice = (props) => {
  const {
    privacyCookiePolicyNotice,
    privacyCookiePolicyNoticeText,
  } = props.options;
  if (privacyCookiePolicyNotice) {
    const CookiePolicy = loadable(() => import(`../CookiePolicy/index.js`));
    return <CookiePolicy content={privacyCookiePolicyNoticeText} />;
  }
  return null;
};

const arrowClickHandler = () => {
  scrollTo(0, 350);
};

export const sendEmail = (event, email) => {
  event.preventDefault();
  window.location = `mailto:${decodeEntities(email)}`;
};

const Footer = (props) => {
  const { data, location = {} } = props;
  const {
    legalNavigation,
    footerNavigation,
    siteSettings,
    wordpressWpSettings,
  } = data;
  const { items: legalNav } = legalNavigation;
  const { items: footerNav } = footerNavigation;
  const {
    footerTagline,
    navigationInFooter,
    footerCredit,
  } = siteSettings.options;
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>
        <div className="footer__inner">
          <div className="footer-main">
            <div className="inner">
              <div className="wrap">
                <div className="left">
                  <GatsbyLink to="/" className="logo-footer">
                    <img alt="" src={FooterLogoReverse} />
                  </GatsbyLink>
                  {footerTagline && (
                    <RenderContent
                      className="tagline"
                      content={footerTagline}
                    />
                  )}
                  {siteSettings.options.socialLinksFooter && <SocialLinks />}
                </div>
                <div className="right">
                  <div
                    className="scroll-arrow"
                    onClick={() => arrowClickHandler()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 39">
                      <path
                        className="dynamic-fill"
                        d="M31.56,23.87a1.65,1.65,0,0,0-.06-2.3,1.58,1.58,0,0,0-2.26.06L17.6,34.09V1.62a1.6,1.6,0,1,0-3.2,0V34L2.76,21.63A1.58,1.58,0,0,0,.5,21.57a1.64,1.64,0,0,0-.06,2.3l13.3,14.18a3.16,3.16,0,0,0,4.55,0Z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="footer-nav">
                    {navigationInFooter &&
                      footerNav &&
                      footerNav.map((item, index) => (
                        <div className="group" key={index}>
                          {item.children && (
                            <ul>
                              {item.children.map((item, index) => (
                                <li key={index}>
                                  <GatsbyLink to={item.url}>
                                    {item.title}
                                  </GatsbyLink>
                                  {item.children && (
                                    <ul>
                                      {item.children.map((item, index) => (
                                        <li key={index}>
                                          <GatsbyLink to={item.url}>
                                            {item.title}
                                          </GatsbyLink>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                  </div>
                  {footerCredit && (
                    <div
                      className="credit"
                      dangerouslySetInnerHTML={{ __html: footerCredit }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="disclaimers">
            <div className="wrap">
              <div className="copyright">
                <span>
                  ©{currentYear} {wordpressWpSettings.title}
                </span>
              </div>

              {legalNav && (
                <div className="navigation">
                  <ul>
                    {legalNav.map((item, index) => (
                      <li key={index}>
                        <GatsbyLink to={item.url} decode={true}>
                          {item.title}
                        </GatsbyLink>
                      </li>
                    ))}
                    {location.pathname === "/" ? (
                      <li className="distl">
                        <span>
                          Website by{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://distl.com.au/"
                          >
                            Distl
                          </a>
                        </span>
                      </li>
                    ) : (
                      <li className="distl">
                        <span>Website by Distl</span>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
      <CookieNotice options={siteSettings.options} />
    </>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
              children: wordpress_children {
                title
                url
                classes
                slug: object_slug
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerCredit
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
