import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Logo } from "../../../theme/Logo";
import HeaderNav from "../HeaderNav";
import Headroom from "react-headroom";
import GatsbyLink from "../GatsbyLink";

import * as HeaderLogo from "../../../theme/logos/pilbara-solar.png";
import * as HeaderLogoReverse from "../../../theme/logos/pilbara-solar-reversed.png";

import "./Header.scss";

const isClient = typeof window !== "undefined";

class Header extends Component {
  constructor(props) {
    super(props);
    this.headerDivRef = React.createRef();
    this.state = {
      contactActive: false,
      navActive: false,
      hoverClear: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (isClient) {
      window.addEventListener("scroll", this.handleScroll, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  /*
   * Apply background to header on scroll.
   */
  handleScroll = (e) => {
    const headerDivElem = this.headerDivRef?.current;
    if (headerDivElem) {
      // we do not want to apply a background on scroll if its on the light theme.
      if (!headerDivElem.classList.contains("theme-light")) {
        if (!headerDivElem.classList.contains("bg-dark")) {
          headerDivElem.classList.add("bg-dark");
        }
        if (window.scrollY < 4) {
          if (headerDivElem.classList.contains("bg-dark")) {
            headerDivElem.classList.remove("bg-dark");
          }
        }
      }
    }
  };

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if (isClient) {
        let childrenDomElements = document.querySelectorAll(".children");
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains("open")) {
            childrenDomElements[i].classList.remove("open");
          }
        }
        if (document.body.classList.contains("nav-open")) {
          this.toggleNav();
        }
      }
    }
  };

  toggleNav = (event) => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add("nav-open");
      }
      this.setState({
        contactActive: false,
        navActive: true,
      });
    } else {
      if (isClient) {
        document.body.classList.remove("nav-open");
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = (event) => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    this.setState({
      navActive: false,
    });
  };

  resetHoverClear = () => {
    this.setState({ hoverClear: true, navActive: false });
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    setTimeout(() => {
      this.setState({ hoverClear: false });
    }, 1000);
  };

  render() {
    const { navActive, hoverClear } = this.state;
    const { data } = this.props;
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      siteSettings,
      wordpressWpSettings,
    } = data;
    const { options } = siteSettings;
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation;
    const { items: aboveNavItems } = abovePrimaryNavigation;
    const theme = this.props.theme;
    return (
      <Headroom disableInlineStyles={true} pinStart={35}>
        <header className={options.invertHeaderColours ? "invert" : ""}>
          <div className="header-notice">
            <div className="wrap">
              <div
                dangerouslySetInnerHTML={{ __html: options.globalSiteNotice }}
              />
            </div>
          </div>
          <div ref={this.headerDivRef} className={`header-main theme-${theme}`}>
            <div className="wrap">
              <div className="inner">
                <GatsbyLink to="/" className="logo">
                  {theme === "light" && !navActive ? (
                    <img alt="" src={HeaderLogo} />
                  ) : (
                    <img alt="" src={HeaderLogoReverse} />
                  )}
                </GatsbyLink>
                <div className="nav">
                  <nav className={`nav-inner${navActive ? " active" : ""}`}>
                    {options.navigationAbovePrimaryMenu && (
                      <HeaderNav
                        active={navActive}
                        className="above-head-navigation-mobile"
                        resetHoverClear={this.resetHoverClear}
                        hoverClear={hoverClear}
                        navItems={aboveNavItems}
                        baseUrl={baseUrl}
                        toggleNav={this.toggleNav}
                      />
                    )}
                    <HeaderNav
                      active={navActive}
                      className="navigation"
                      navItems={navItems}
                      resetHoverClear={this.resetHoverClear}
                      hoverClear={hoverClear}
                      baseUrl={baseUrl}
                      toggleNav={this.toggleNav}
                    />
                  </nav>
                  <div className="header-contact">
                    <GatsbyLink to="/contact-us/">Contact Us</GatsbyLink>
                  </div>
                  <div className="controls">
                    {options.showSearchIconInMainNavigation && (
                      <GatsbyLink
                        className={`control-search icon-search`}
                        to="/search/"
                        aria-label="Toggle Search"
                      />
                    )}
                    <button
                      type="button"
                      className={`control-nav icon-hamburger ${
                        navActive ? "active" : ""
                      }`}
                      onClick={this.toggleNav}
                      aria-label="Toggle Menu"
                    >
                      <span className={`burger ${navActive ? "active" : ""}`}>
                        <span />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            globalSiteNotice
            locations {
              addressDisplay
              email
              phone
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);
