import React, { useEffect, useState } from "react";
import { Card } from "../../Card";
import "./PostListItem.scss";
import { decodeEntities, getCategory } from "../../../utils/helpers";
import { dateI18n } from "@wordpress/date";

export default (props) => {
  const { data, dateFormat } = props;
  const { title, path, featured_media, date } = data;
  const dateFormatted = dateFormat && date && dateI18n(dateFormat, date);
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLocation(window?.location?.pathname);
  }, []);

  const getCategoryFromData = (nodeData, allCategories) => {
    if (location.includes("projects")) {
      return getCategory(nodeData.project_category[0], allCategories);
    } else if (location.includes("news")) {
      return nodeData.categories[0].name;
    }
  };

  if (location.includes("resources")) {
    return (
      <div className="post-list-item">
        <Card
          heading={decodeEntities(title)}
          cta={path}
          ctaShort
          ctaAlign="left"
        />
      </div>
    );
  } else if (location.includes("/news/") || location.includes("/projects/")) {
    return (
      <div className="post-list-item">
        <Card
          heading={decodeEntities(title)}
          image={featured_media}
          cta={path}
          ctaShort
          ctaAlign="left"
          preText={getCategoryFromData(data, props?.categories)}
          date={location.includes("news") ? dateFormatted : undefined}
        />
      </div>
    );
  } else {
    return (
      <div className="post-list-item">
        <Card
          heading={decodeEntities(title)}
          image={featured_media}
          cta={path}
          ctaShort
          ctaAlign="none"
          theme="light-grey"
        />
      </div>
    );
  }
};
