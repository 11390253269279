import React from "react";
import "./Card.scss";
import { CTA } from "../CTA/CTA";
import { ImagePass } from "../Image/ImagePass";
import GatsbyLink from "../GatsbyLink";

/**
 * Card style layout
 *
 */
export const Card = ({
  heading,
  subHeading,
  text,
  preText,
  postText,
  trimText,
  icon,
  image,
  imageFit,
  moreImageSpacing,
  cta,
  ctaShort,
  span,
  variant,
  alignContent,
  theme,
  ctaAlign,
  date,
}) => {
  const hasContent = !!(
    preText ||
    heading ||
    subHeading ||
    text ||
    postText ||
    React.isValidElement(icon)
  );
  const hasImage = React.isValidElement(image) || !!image?.source_url;
  /**
   * Toggle helper for trim-text
   * @type {import("react").PointerEventHandler<import("react").ElementRef<'div'>>}
   */
  const trimContent = (e) => e.currentTarget.classList.toggle("show-trimmed");
  return (
    <div
      className={`card card-${variant} card-theme-${theme} span-${span} ${
        !hasImage ? `no-image` : ``
      }`}
      style={span > 1 ? { "--card-span": span } : {}}
    >
      {hasImage ? (
        <div
          className={`card-image${
            moreImageSpacing ? ` more-image-spacing` : ``
          }${imageFit ? ` ${imageFit}` : ``}${
            !hasContent ? " large-image" : ""
          }`}
          onClick={cta?.action}
        >
          <div className="image-container">
            {cta && cta.link ? (
              <GatsbyLink to={ctaShort ? cta : cta.link}>
                {React.isValidElement(image) ? (
                  image
                ) : (
                  <ImagePass src={image} />
                )}
              </GatsbyLink>
            ) : React.isValidElement(image) ? (
              image
            ) : (
              <ImagePass src={image} />
            )}
          </div>
        </div>
      ) : null}
      {hasContent ? (
        <div
          className={`card-content align-${alignContent}${
            cta ? ` has-cta` : ``
          }`}
        >
          {icon ? <div className="card-icon">{icon}</div> : null}
          {preText && date ? (
            <div className={`card-preText theme-${theme}-low-contrast`}>
              <span className="dark-text">{preText} </span>
              {date}
            </div>
          ) : (
            <div className={`card-preText theme-${theme}-low-contrast`}>
              {preText}
            </div>
          )}
          {heading ? <h3 className="card-heading">{heading}</h3> : null}
          {subHeading ? (
            <span className={`card-subHeading theme-${theme}-low-contrast`}>
              {subHeading}
            </span>
          ) : null}
          {text ? (
            <div
              onClick={
                !["file", "large-text"].includes(variant) && trimText
                  ? trimContent
                  : undefined
              }
              className={`card-text${
                !["file", "large-text"].includes(variant) && trimText
                  ? ` trim-text`
                  : ``
              }`}
            >
              <div>{text}</div>
            </div>
          ) : null}
          {postText ? <div className="card-postText">{postText}</div> : null}
          {ctaAlign !== "none" && cta ? (
            <div className={`card-cta card-cta-${ctaAlign}`}>
              {ctaShort ? (
                <CTA link={cta} text={cta.title} />
              ) : (
                <CTA link={cta.url} text={cta.title} />
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

Card.defaultProps = {
  heading: undefined,
  subHeading: undefined,
  trimText: false,
  text: undefined,
  preText: undefined,
  postText: undefined,
  cta: undefined,
  icon: undefined,
  image: undefined,
  imageFit: undefined,
  moreImageSpacing: false,
  span: 1,
  variant: "default",
  alignContent: "left",
  theme: "light",
  ctaAlign: "left",
  ctaShort: false,
  date: undefined,
};
