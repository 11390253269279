import React from "react";
import "../../sass/global/styles.scss";
import Header from "../Header/";
import Footer from "../Footer";

const Layout = ({ children, location, theme, className }) => (
  <div className={className} id="layout">
    <Header location={location} theme={theme} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
);

export default Layout;
