import React, { Component, Fragment } from "react";
import GatsbyLink from "../GatsbyLink";
import CaretRightSymbol from "../../../static/icons/pilbara_caret_right.svg";
import "./HeaderNav.scss";

const isClient = typeof window !== "undefined";

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, resetHoverClear } = props;
  const { object_id, title, url, children, target } = item;
  const isOpen = openNavKey === object_id;
  return (
    <span
      className={`nav--item__wrapper ${children ? "nav--item__parent" : ""}`}
      key={object_id}
    >
      <GatsbyLink
        to={url}
        onClick={(event) => resetHoverClear(event, true)}
        target={target ? target : null}
        activeClassName="active"
        aria-label={title}
        decode={true}
        className="nav--item"
      >
        {title}
      </GatsbyLink>
      {!children && (
        <div className="nav--item__icon">
          <img src={CaretRightSymbol} alt="" />
        </div>
      )}
      {children && children[0]?.url !== "#gatsby" && (
        <Fragment>
          <NavChildren
            baseUrl={baseUrl}
            childNav={children}
            isOpen={isOpen}
            resetHoverClear={resetHoverClear}
          />
          <div
            className={`nav--item__icon expand ${isOpen ? "open" : "closed"}`}
            onClick={(event) => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <img src={CaretRightSymbol} alt="" />
          </div>
        </Fragment>
      )}
    </span>
  );
};

const NavChildren = (props) => {
  const { childNav, isOpen = null, resetHoverClear } = props;
  return (
    <>
      <ul className={`children children__menu ${isOpen ? "open" : "close"}`}>
        {childNav.map((child, index) => {
          const { title, url, target } = child;
          return (
            <li
              className="children__menu__item--wrapper"
              key={`child-item--${index}`}
            >
              <GatsbyLink
                to={url}
                onClick={(event) => resetHoverClear(event, true)}
                target={target ? target : null}
                activeClassName="children__menu__item--active"
                aria-label={title}
                decode={true}
                className="children__menu__item"
              >
                {title}
              </GatsbyLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener("keydown", this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener("keydown", this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  };

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey)
      return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  };

  render() {
    const {
      active,
      navItems,
      baseUrl,
      className,
      hoverClear,
      resetHoverClear,
    } = this.props;
    const { openNavKey } = this.state;
    return (
      <ul className={className}>
        {navItems &&
          navItems.map(
            (col, index) =>
              index < 10 &&
              col.url !== "#gatsby" && (
                <li key={index}>
                  <NavItem
                    key={col.url}
                    item={col}
                    baseUrl={baseUrl}
                    toggleSubNav={this.toggleSubNav}
                    openNavKey={openNavKey}
                    resetHoverClear={resetHoverClear}
                    hoverClear={hoverClear}
                  />
                </li>
              )
          )}
      </ul>
    );
  }
}
